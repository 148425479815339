(function() {
  if ('values' in Object) {
    return;
  }
  Object.values = function(o) {
    return Object.keys(o).map(function(e) {
      return o[e];
    });
  };
})();
